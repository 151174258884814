import { useEffect, useState, useContext } from 'react';
import {
  Manage,
  ManageBox,
  TableFilters,
  TableTitle,
  TextBlackSmall,
  TextBlackdark,
  TextGreyThin,
  Overlay,
  TextBlack,
  Block,
} from 'base/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { any, array, bool, func } from 'prop-types';
import { fetchAllAFs } from 'store/slices/accFilters';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/trash.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { deleteAF } from 'store/slices/accFilters';
import Responsive from 'context/responsive';
import Pagination from 'base/components/Pagination';

const Text = styled.span`
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0rem;
  text-align: left;
  color: ${(props) => props.theme.colors.white};
  background: rgba(50, 49, 49, 0.7);
  border-radius: 11.5px;
  padding: 3px 8px;
  margin-top: 5px !important;
  width: fit-content;
`;

const ManageRight = styled(Manage)`
  text-align: center;
`;

const ManageBoxRight = styled(ManageBox)`
  right: 40px;
  padding: 5px 10px!important;
`;

const Box = styled.div`
  padding-top: 80px;
  p {
    text-align: center;
  }
`;

const OverManage = styled(Overlay)`
  z-index: 19;
  background: inherit;
`;

const Boxe = styled(Block)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const BoxBordered = styled.div`
  border-radius: 10px;
  border: ${(props) => props.theme.borders.tableBorder};
  padding: 18px;
  width: 100%;
`;

const Rowe = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    font-size: 0.75rem;
  }
  .title {
    p {
      width: 120px;
    }
  }
  .value {
    width: 100%;
  }
`;

export default function TableFilterAccount({
  handleFilterAccount,
  setEdit,
  setFilterAccountEdit,
  executeScroll,
  setAddFilter,
  addFilter,
  setResult
}) {
  const [show, setShow] = useState(false);
  const [noFilters, setNoFilters] = useState(false);
  const { t: homeT } = useTranslation('home');
  const dispatch = useDispatch();
  const { accFilters: list, deleted, total_pages } = useSelector((state) => state.accFReducer);

  const ctx = useContext(Responsive);

  const [pageNumber, setPageNumber] = useState(1);

  const handleShow = (idx) => () => {
    setShow((state) => ({
      ...state,
      [idx]: !show[idx],
    }));
  };

  const handleShowOff = () => () => {
    setShow(false);
  };

  const handleShowForm = (lead) => () => {
    handleFilterAccount(lead);
    setFilterAccountEdit(lead);
    setEdit(true);
    executeScroll();
  };

  useEffect(() => {
    if(deleted){
      setAddFilter(!addFilter);
    }
  /* eslint-disable */
  }, [deleted]);
  /* eslint-enable */

  const handleDelete = (id) => () => {
    dispatch(deleteAF(id)); 
    setResult("Filter was deleted");
  };

  useEffect(() => {
    dispatch(fetchAllAFs({per_page: 50, page: pageNumber}));
    /* eslint-disable */
  }, [dispatch]);
    /* eslint-enable */

  useEffect(() => {
    setNoFilters(list.length === 0);
  }, [list]);

  function handlePageNext() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function handlePagePrev() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      dispatch(fetchAllAFs({per_page: 50, page: pageNumber}));
    }
    /* eslint-disable */
  }, [pageNumber, dispatch]);
   /* eslint-enable */
  
  return (
    <>
      {ctx.isMobile ? (
        <Boxe>
          {list &&
            list.map((lead, idx) => (
              <BoxBordered key={idx}>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('name')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>{lead.name}</TextBlackdark>
                  </div>

                  <ManageRight onClick={handleShow(idx)}>
                    <Nav />
                    {show[idx] && (
                      <>
                        <OverManage onClick={handleShowOff}></OverManage>
                        <ManageBoxRight>
                          <a onClick={handleShowForm(lead)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>
                          <a onClick={handleDelete(lead.id)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                        </ManageBoxRight>
                      </>
                    )}
                  </ManageRight>
                </Rowe>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('website')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>  {lead?.ga_view?.website_name}</TextBlackdark>
                  </div>
                </Rowe>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('filters')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>
                      {lead &&
                      lead?.lead_filters_attributes?.map((filter, idx) => (
                        <div key={idx}>
                          <TextBlackdark>{filter.key}: {filter.value}</TextBlackdark>
                          
                        </div>
                      ))}
                    </TextBlackdark>
                  </div>
                </Rowe>
              </BoxBordered>
            ))}
        </Boxe>
      ) : (  list && list.length > 0 && (
        <>
          <TableFilters>
            <tbody>
              <tr>
                <th>
                  <TableTitle>{homeT('name')}</TableTitle>
                </th>
                <th>
                  <TableTitle>{homeT('website')}</TableTitle>
                </th>
                <th>
                  <TableTitle>{homeT('filters')}</TableTitle>
                </th>
                <th>
                  <TableTitle>{homeT('manage')}</TableTitle>
                </th>
              </tr>
              {list?.map((lead, idx) => (
                <tr key={lead.id}>
                  <td>
                    <TextBlackdark> {lead.name}</TextBlackdark>
                  </td>
                  <td>
                    <TextBlackdark>
                      {lead?.ga_view?.website_name}
                      {lead?.ga_view?.sunsetted && (
                        <Text>{homeT('sunsetted')}</Text>
                      )}
                    </TextBlackdark>
                  </td>
                  <td>
                    {lead && lead?.lead_filters_attributes && Array.isArray(lead?.lead_filters_attributes) &&
                      lead?.lead_filters_attributes?.map((filter, idx) => (
                        <div key={idx}>
                          <TextBlackdark> {
                            filter.key === 'page_path' && 'Page Path' ||
                              filter.key === 'country' && 'Visitor Location Country' ||
                              filter.key === 'region' && 'Visitor Location State/Province' ||
                              filter.key
                          }: </TextBlackdark>
                          <TextBlackSmall> {filter.value} </TextBlackSmall>
                        </div>
                      ))}
                  </td>
                  <td>
                    <ManageRight onClick={handleShow(idx)}>
                      <Nav />
                      {show[idx] && (
                        <>
                          <OverManage onClick={handleShowOff}></OverManage>
                          <ManageBoxRight>
                            <a onClick={handleShowForm(lead)}>
                              <Edit />
                              <span>{homeT('edit')}</span>
                            </a>
                            <a onClick={handleDelete(lead.id)}>
                              <Delete />
                              <span>{homeT('delete')}</span>
                            </a>
                          </ManageBoxRight>
                        </>
                      )}
                    </ManageRight>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableFilters>
        </>)
      )}
      {noFilters && (
        <Box>
          <TextGreyThin>{homeT('noFilters')}</TextGreyThin>
        </Box>
      )}
   
      {!noFilters && Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber)}
    </>
  );
}

TableFilterAccount.propTypes = {
  list: array,
  handleFilterAccount: func,
  setEdit: func,
  filterAccountEdit: any,
  setFilterAccountEdit: func,
  executeScroll: func,
  setAddFilter: func,
  addFilter: bool,
  setResult: func
};
