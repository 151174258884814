import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllHidingRules } from 'store/slices/hidingRules';
import { useTranslation } from 'react-i18next';
import {
  FlexWrapper,
  AccentButton,
  Modal,
  Overflow,
  Block,
  Page,
  Loader,
  SearchForm,
  Result,
  TitlePage
} from 'base/styled';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import HiddenForm from './HiddenForm';
import HiddenTable from './HiddenTable';
import TooltipDark from 'base/components/TooltipDark';
import Responsive from 'context/responsive';
import Pagination from 'base/components/Pagination';

const Form = styled(Block)`
  margin: 0 auto;
`;

const Flex = styled(FlexWrapper)`
  padding: 10px 0 0;
  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding-top: 40px;
  }
  h1 {
    @media (max-width: 768px) {
      padding-right: 18px;
    }
  }
`;

const Tooltip = styled(TooltipDark)`
  top: -30px;
  right: -107%;
  left: auto;
  span {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    right: -90%;
    span {
      max-width: 320px;
      left: auto;
      right: 0px;
    }
  }
`;

const HiddenPage = styled(Page)`
  margin-left: 94px;
  padding-top: 75px;
  width: 75%;
  td:first-of-type {
    width: 45%;
  }
  h1 {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    td:first-of-type {
      width: 65%;
    }
  }
`;

export default function Index() {
  const { t: hiddenT } = useTranslation('hidden');
  const { t: rootT } = useTranslation();
  const [show, setShow] = useState();
  const [success, setSuccess] = useState();
  const [search, setSearch] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [text, setText] = useState();
  const dispatch = useDispatch();
  const { hidingRules, status, total_pages } = useSelector(
    (state) => state.hidingRulesReducer
  );
  const ctx = useContext(Responsive);

  const [pageNumber, setPageNumber] = useState(1);
  const [sentSearch, setSentSearch] = useState(false);

  useEffect(() => {
    if(!sentSearch) {
      dispatch(fetchAllHidingRules({ hidingRules, per_page: 100, page: pageNumber }));
      setSearchResults(hidingRules);
    } 
    /* eslint-disable */
  }, [dispatch, hidingRules.length]);
  /* eslint-enable */

  useEffect(() => {
    setSearchResults(hidingRules);
    /* eslint-disable */
  }, [search]);
  /* eslint-enable */

  function handleText(e) {
    setText(e.target.value);
    setSearchResults(hidingRules.filter((el) => {
      return el?.keyword?.toLowerCase().includes(text.toLowerCase());
    }));
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    setSentSearch(true);
    setText(text);
    dispatch(fetchAllHidingRules({ query: text, per_page: 100 }));
  }

  function handleSearchClick() {
    if(text) {
      setSearchResults(hidingRules.filter((el) => {
        return el.keyword.toLowerCase().includes(text?.toLowerCase());
      }));
    } else {
      setSearchResults(hidingRules);
    }
    setSearch(true);
  }
  
  const [setUserList] = useState([]);

  function AddRuleHandler(data) {
    setUserList((prevList) => {
      return [
        ...prevList,
        {
          website: data.website,
          keyword: data.keyword,
          file: data.file,
        },
      ];
    });
  }

  function showHandler() {
    setShow(true);
  }

  function setShowOff() {
    setShow();
    setSuccess();
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  let list = text ? searchResults : hidingRules;

  function handlePageNext() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function handlePagePrev() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      dispatch(fetchAllHidingRules({ hidingRules, per_page: 100, page: pageNumber }));
    }
    /* eslint-disable */
  }, [pageNumber, dispatch]);
   /* eslint-enable */

  return (
    <HiddenPage>
      {status === 'loading' ? (
        <Loader />
      ) : (
        <>
          <Flex>
            <TitlePage>
              {hiddenT('hidden')}
              <Tooltip text={hiddenT('tooltip')} />
            </TitlePage>
            {!ctx.isMobile && (
              <SearchForm onSubmit={handleSearchSubmit}>
                <SearchIcon onClick={handleSearchClick} />
                <input
                  type="search"
                  className='light'
                  onChange={handleText}
                  value={text}
                />
              </SearchForm>
            )}
            <AccentButton onClick={showHandler}>
              {rootT('addNewRule')}
            </AccentButton>
          </Flex>
          {ctx.isMobile && (
            <SearchForm onSubmit={handleSearchSubmit}>
              <SearchIcon onClick={handleSearchClick} />
              <input
                type="search"
                className='light'
                onChange={handleText}
              />
            </SearchForm>
          )}
          {show && (
            <>
              <Modal>
                <Overflow onClick={setShowOff}></Overflow>
                {!success && (
                  <Form>
                    <HiddenForm
                      onAddRule={AddRuleHandler}
                      setShowOff={setShowOff}
                      setSuccess={setSuccess}
                      setShow={setShow}
                      hidingRules={hidingRules}
                    />
                  </Form>
                )}
              </Modal>
            </>
          )}
          {success && <Result>{success}</Result>}
          <HiddenTable list={list?.slice().sort((a, b) => b.id - a.id)} setResult={setSuccess}/>
          {Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber)}
        </>
      )}
    </HiddenPage>
  );
}
