import { useState, useEffect, useContext } from 'react';
import {
  Manage,
  ManageBox,
  TableFilters,
  TableTitle,
  TextBlackdark,
  TextGreyThin,
  Overlay,
  TextBlack,
  Block
} from 'base/styled';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUserFs } from 'store/slices/userFilters';
import { any, array, bool, func } from 'prop-types';
import { ReactComponent as Nav } from 'assets/images/navigation.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as Delete } from 'assets/images/trash.svg';
import styled from 'styled-components';
import { deleteUF } from 'store/slices/userFilters';
import Responsive from 'context/responsive';
import Pagination from 'base/components/Pagination';


const Box = styled.div`
  padding-top: 80px;
  p {
    text-align: center;
  }
`;

const ManageBoxRight = styled(ManageBox)`
  right: 18px;
  padding: 5px 10px!important;
`;

const Span = styled.span`
  font-size: .75rem;
  font-style: normal;
  font-weight: 600;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  color: #2C2C2C;
`;

const Text = styled.span`
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0rem;
  text-align: left;
  color: ${props => props.theme.colors.white};
  background: rgba(50, 49, 49, 0.7);
  border-radius: 11.5px;
  padding: 3px 8px;
  margin-top: 5px!important;
  width: fit-content;
`;

const OverManage = styled(Overlay)`
z-index: 19;
background: inherit;
`;

const Boxe = styled(Block)`
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const BoxBordered = styled.div`
  border-radius: 10px;
  border: ${(props) => props.theme.borders.tableBorder};
  padding: 18px;
  width: 100%;
`;

const Rowe = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    font-size: 0.75rem;
  }
  .title {
    p {
      width: 120px;
    }
  }
  .value {
    width: 100%;
  }
`;

export default function TableFilterUser({
  handleFilterUser,
  setEdit,
  setFilterUserEdit,
  executeScroll,
  edit,
  naics,
  gic,
  setAddFilter,
  addFilter,
  users,
  setResult
}) {

  const [show, setShow] = useState(false);
  const [noFilters, setNoFilters] = useState(false);
  const { t: homeT } = useTranslation('home');
  const dispatch = useDispatch();
  
  const ctx = useContext(Responsive);
  const [pageNumber, setPageNumber] = useState(1);

  const {userFilters, deleted, total_pages} = useSelector((state) => state.userFReducer);

  const handleShow = (idx) => () => {
    setShow(state => ({
      ...state,
      [idx]: !show[idx]
    }));
  };

  useEffect(() => {
    dispatch(fetchAllUserFs({per_page:50, page: pageNumber}));
    /* eslint-disable */
  }, [dispatch, show, userFilters?.length]);
    /* eslint-enable */

  const handleShowOff = () => () => {
    setShow(false);
  };

  const handleShowForm = (lead) => () => {
    handleFilterUser(lead);
    setFilterUserEdit(lead);
    setEdit(true);
    executeScroll();
  };
  
  const handleDelete = (id) => () => {
    dispatch(deleteUF(id));
    //handleDeleteFilter(id);
    setResult("Filter was deleted");
  };

  useEffect(() => {
    if(deleted){
      setAddFilter(!addFilter);
    }
  /* eslint-disable */
  }, [deleted]);
  /* eslint-enable */

  useEffect(() => {
    setNoFilters(userFilters?.length === 0);
  }, [userFilters, edit]);

  function handlePageNext() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  function handlePagePrev() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  useEffect(() => {
    if (pageNumber >= 1) {
      dispatch(fetchAllUserFs({per_page: 50, page: pageNumber}));
    }
    /* eslint-disable */
  }, [pageNumber, dispatch]);
   /* eslint-enable */

  return (
    <>
      {ctx.isMobile ? (
        <Boxe>
          {userFilters &&
            userFilters.map((lead, idx) => (
              <BoxBordered key={idx}>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('name')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>{lead.name}</TextBlackdark>
                  </div>

                  <Manage onClick={handleShow(idx)}>
                    <Nav />
                    {show[idx] && (
                      <>
                        <OverManage onClick={handleShowOff}></OverManage>
                        <ManageBoxRight>
                          {!edit && <a onClick={handleShowForm(lead)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>}
                          <a onClick={handleDelete(lead.id)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                        </ManageBoxRight>
                      </>
                    )}
                  </Manage>
                </Rowe>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('website')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>  {lead?.ga_view?.website_name}</TextBlackdark>
                  </div>
                </Rowe>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('filters')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>
                      {lead &&
                      lead?.lead_filters_attributes?.map((filter, idx) => (
                        <div key={idx}>
                          <TextBlackdark>{filter.key}:  {filter.value}</TextBlackdark>
                        </div>
                      ))}
                    </TextBlackdark>
                  </div>
                </Rowe>
                <Rowe>
                  <div className="title">
                    <TextBlack>{homeT('assignTo')}</TextBlack>
                  </div>
                  <div className="value">
                    <TextBlackdark>
                      {lead?.users &&
                      lead?.users?.map((filter, idx) => (
                        <div key={idx}>
                          <TextBlackdark>
                            {filter.first_name} {filter.last_name}
                          </TextBlackdark>
                        </div>
                      ))}
                    </TextBlackdark>
                  </div>
                </Rowe>
               
              </BoxBordered>
            ))}
        </Boxe>
      ) : (userFilters && userFilters?.length > 0 &&
        <TableFilters>
          <tbody>
            <tr>
              <th>
                <TableTitle>{homeT('name')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('website')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('filters')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('appliedTo')}</TableTitle>
              </th>
              <th>
                <TableTitle>{homeT('manage')}</TableTitle>
              </th>
            </tr>
            {userFilters?.map((lead, idx) => (
              
              <tr key={idx}>
                <td>
                  <TextBlackdark> {lead?.name}</TextBlackdark>
                </td>
                <td>
                  <TextBlackdark>
                    {lead?.ga_view?.website_name}
                    {lead?.ga_view?.sunsetted && (
                      <Text>{homeT('sunsetted')}</Text>
                    )}
                  </TextBlackdark>
                </td>
                <td>
                  {lead?.lead_filters_attributes &&
                    lead?.lead_filters_attributes?.map((filter, idx) => (
                      <div key={idx}>
                        <TextBlackdark>
                          {
                            filter.key === 'gic_industry' && 'GIC' ||
                            filter.key === 'industry' && 'NAICS' ||
                            filter.key === 'company_name' && 'Company name' ||
                            filter.key === 'full_contact_city' && 'Headquarters City' ||
                            filter.key === 'full_contact_region' && 'Headquarters Region' ||
                            filter.key === 'full_contact_country' && 'Headquarters Country' ||
                            filter.key === 'page_path' && 'Page Path' ||
                            filter.key === 'time_on_page' && 'Time on Website' ||
                            filter.key === 'country' && 'Visitor Location Country' ||
                            filter.key === 'region' && 'Visitor Location State/Province' ||
                            filter.key === 'city' && 'Visitor Location City' ||
                            filter.key === 'tag' && 'Tag' ||
                            filter.key === 'domain' && 'Domain' ||
                            filter.key === 'views_count_of_specific_page' && 'Views of specific page' ||
                            filter.key === 'time_on_specific_page' && 'Time on specific page' ||
                            filter.key === 'new_visitor' && 'New visitor' ||
                            filter.key
                          }:{' '}
                          {filter.key === 'gic_industry' ||
                          filter.key === 'industry' ? (
                              filter.key === 'gic_industry' ? (
                                gic
                                  .filter(({ value }) =>
                                    filter.value.split(',').includes(value)
                                  )
                                  .map((filter, idx) => (
                                    <div key={idx}>
                                      <Span>{filter.label}</Span>
                                    </div>
                                  ))
                              ) : (
                                ([].concat(...naics.map(({options}) => options || []))).filter(({ value }) =>
                                  filter.value.split(',').includes(value)
                                )
                                  .map((filter, idx) => (
                                    <div key={idx}>
                                      <Span>{filter.label}</Span>
                                    </div>
                                  ))
                              )
                            ) : filter.key === 'assigned' ? 
                              filter.value == 'any' ? 'any' :
                                users?.filter( user  => 
                                  filter.value.split(',').includes(user.id?.toString()) )
                                  .map((filter, idx) => (
                                    <div key={idx}>
                                      <Span>{filter.full_name}</Span>
                                    </div>
                                  )) : filter.operator == 'between' ? (
                                <Span>{filter?.multiple_filter?.value_from + '-' + 
                                filter?.multiple_filter?.value_to} </Span>
                              ) : filter.key == "time_on_specific_page" ? (
                                <Span>{filter.multiple_filter.time_value}</Span>
                              ) : filter.key == "views_count_of_specific_page" ? (
                                <Span>{filter.multiple_filter.count_value}</Span>
                              )
                                : (
                                  <Span>{filter.value} </Span>
                                )}
                        </TextBlackdark>
                      </div>
                    ))}
                </td>
                <td>
                  {lead?.users &&
                    lead?.users?.map((filter, idx) => (
                      <div key={idx}>
                        <TextBlackdark>
                          {filter.first_name} {filter.last_name}
                        </TextBlackdark>
                      </div>
                    ))}
                  <TextBlackdark> {lead?.user} </TextBlackdark>
                </td>
                <td>
                  <Manage onClick={handleShow(idx)}>
                    <Nav />
                    {show[idx] && (
                      <>
                        <OverManage onClick={handleShowOff}></OverManage>
                        <ManageBoxRight>
                          {!edit && <a onClick={handleShowForm(lead)}>
                            <Edit />
                            <span>{homeT('edit')}</span>
                          </a>}
                          <a onClick={handleDelete(lead.id)}>
                            <Delete />
                            <span>{homeT('delete')}</span>
                          </a>
                        </ManageBoxRight>
                      </>
                    )}
                  </Manage>
                </td>
              </tr>
            ))}
          </tbody>
        </TableFilters>
      )}
      {noFilters && (
        <Box>
          <TextGreyThin>{homeT('noFilters')}</TextGreyThin>
        </Box>
      )}
      {!noFilters && Pagination(total_pages, pageNumber, handlePageNext, handlePagePrev, setPageNumber)}
    </>
  );
}

TableFilterUser.propTypes = {
  handleFilterUser: func,
  setEdit: func,
  setFilterUserEdit: func,
  naics: array,
  gic: array,
  edit: bool,
  executeScroll: func,
  setAddFilter: func,
  addFilter: bool, 
  users: any,
  setResult: func
};
